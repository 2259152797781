.btn {
    display: inline-block;
    font-weight: 500;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 12px 22px;
    font-size: 0.8125rem;
    line-height: 1.5;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-size: 16px;
    height: auto;
    border: none;
    border-radius: 12px;
    transition: color .4s, background-color .4s, border-color .4s, box-shadow .4s;
    font-family: var(--primary-police);
    cursor: pointer;
}

.ant-btn:hover {
    background-color: var(--primary-color) !important;
}

.btn-primary {
    background-color: var(--primary-color);
    color: white;
}

.btn-primary-light {
    border: 2px solid var(--primary-color);
    color: var(--primary-color);
}


.btn-secondary {
    background-color: var(--secondary-color);
    color: white;
}

.btn-lg {
    width: -webkit-fill-available;
}

.btn-sm {
    font-size: 0.710938rem;
    line-height: 1.5;
}

.link-edit {
    color: var(--primary-color);
    cursor: pointer;
}

.link-delete {
    color: red;
    cursor: pointer;
}