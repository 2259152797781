.contact-page {
    .section-1 {
        margin-top: 100px;

        .blc-left {
            .blc-left-item {
                margin-bottom: 20px;
                font-family: var(--primary-police);

                .title {
                    font-size: 20px;
                    font-weight: 500;
                }

                .subTitle {
                    font-size: 16px;
                    font-weight: 400;
                }
            }
        }
    }
}