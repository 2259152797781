.home-page {
    font-family: var(--primary-police) !important;

    a {
        text-decoration: none;
        color: black !important;
    }

    .section-1 {
        background-size: cover;
        background-position: center;

        .section-content {
            border-radius: 30px;

            .mouton-img {
                position: absolute;
                right: -361px;
                top: -357px;
                width: 778px;
            }

            .logo-img {
                width: 328px;
            }

            h1 {
                font-size: 112px;
                font-weight: 900;
                font-family: system-ui;
                margin: auto;

                span {
                    color: var(--primary-color);
                }
            }
        }

        .section-desc {
            background-color: black;
            width: 1200px;
            border-radius: 12px;
            padding: 10px;
            position: relative;

            .section-desc-content {
                width: 792px;
            }

            p {
                color: white;
                margin: 0px;
                padding: 15px;
            }
        }
    }

    h3 {
        text-align: center;
        font-family: var(--primary-police);
        margin-top: 65px;
    }


    .section-product {
        margin-top: 50px;

        h1,
        h3 {
            text-align: center;
        }

        h1 {
            color: var(--primary-color);

            span {
                color: black;
            }
        }

        h3 {
            span {
                font-weight: 900;
            }
        }


        .nos-moutons {
            text-align: center;
            color: var(--primary-color);
            font-weight: 800;
            font-size: 35px;
            margin-top: 70px;
            margin-bottom: 40px;
        }

        a {
            color: var(--text-color);
        }
    }

    .voir-plus {
        text-align: center;
        margin-bottom: 42px;

        a {
            font-size: 30px;
            color: var(--primary-color) !important;
            font-weight: 500;
        }
    }
}


@media (max-width: 768px) {
    .section-content {
        .carousel-img {
            height: 200px;
        }
    }

    .section-product .nos-moutons {
        margin-top: 50px;
    }

    .product-item .blc-right .proprietaire {
        padding: 16px;
        margin: 0px 6px;
        top: 200px;
        left: 6px;
        right: 6px;
    }

    .product-item .blc-left .blc-img {
        height: 318px;
    }
}