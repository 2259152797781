.post-page {
    font-family: var(--primary-police);

    .section-1 {
        margin-top: 50px;

        .blc {
            background-color: #F3F3F3;
            height: 380px;
            position: relative;
            border-radius: 20px;
            margin-bottom: 32px;

            .blc-img {
                height: 200px;
                background-color: gray;
                background-size: cover;
                background-position: center;
                position: relative;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;

                .blc-img-backdrop {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: #000;
                    opacity: 0.5;
                    border-top-left-radius: 20px;
                    border-top-right-radius: 20px;
                }

                .blc-cat {
                    color: white;
                    position: absolute;
                    font-family: var(--primary-police);
                    font-size: 17px;
                    left: 15px;
                    top: 15px;
                }
            }

            .blc-title {
                font-size: 20px;
                padding: 15px;
            }

            a {
                color: black;
            }
        }
    }


    .post-detail {
        margin-top: 200px;
        margin-left: auto;
        margin-right: auto;
        width: 900px;

        .ant-breadcrumb-link,
        .ant-breadcrumb-separator {
            font-size: 20px;
            font-family: var(--primary-police);
        }

        .post-breadcrumb {
            a {
                color: var(--primary-color);
            }
        }


        h1 {
            font-size: 36px;
        }

        .content {
            width: 680px;
            margin: auto;
        }

        .post-img {
            width: auto;
            height: 500px;
            border-radius: 50px;
        }

        .post-detail-content {
            padding-top: 23px;
            padding-bottom: 23px;
        }


    }

    :where(.css-dev-only-do-not-override-49qm).ant-breadcrumb a:hover {
        background-color: transparent;
    }
}