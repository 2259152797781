.product-item {
    margin-bottom: 60px;
    transition: color 0.4s ease;
    padding: 20px 20px;

    a {
        text-decoration: none;
        color: black;
    }

    .blc-left {
        .blc-img {
            overflow: hidden;
            height: 390px;
            width: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 35px;
            }
        }
    }

    .blc-right {
        margin-top: 40px;

        .title {
            font-size: 29px;
            font-weight: 700;
        }

        .blc-content-wrap {
            position: relative;
        }

        table {
            width: 100%;
            border: 1px solid black;
            padding: 20px;
            padding-bottom: 50px;
        }

        .blc-item {
            font-size: 17px;
            margin-bottom: 10px;

            span {
                font-weight: 900;
            }
        }

        .proprietaire {
            background-color: black;
            padding: 16px;
            border-radius: 10px;
            margin: 0px 8px;
            position: absolute;
            width: -webkit-fill-available;
            top: 174px;
            left: 15px;
            right: 15px;

            .blc-item {
                font-size: 20px;
                margin: auto;
                color: white;
            }
        }
    }
}

.product-item:focus-within {
    color: var(--primary-color);
}

.product-item:hover {
    color: var(--primary-color);
    background: #8080800d;
    padding: 20px 20px;
    border-radius: 35px;

    .proprietaire {
        background-color: var(--primary-color);
        color: var(--primary-color) !important;
    }

    .blc-item {
        color: var(--primary-color);
    }

    .blc-right {
        .title {
            color: var(--primary-color);
        }
    }
}