.footer {
    padding: 40px 0px;
    background-color: black;
    font-family: var(--primary-police);
    margin-top: 138px;

    .title {
        color: white;
        font-size: 22px;
        font-weight: 600;
    }

    ul {
        padding: 0%;

        li {
            margin-bottom: 5px;

            a {
                color: white;
                font-weight: 600;
            }
        }
    }

    .copyright {
        margin: 20px;
        text-align: center;
        color: white;
        font-size: 14px;
    }
}