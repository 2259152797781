@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import './components/button';
@import './components/form';
@import "~react-image-gallery/styles/scss/image-gallery.scss";


:root {
    --primary-color: #F6A411;
    --secondary-color: #fbb200;
    --tertiary-color: #35c154;
    --white-color: #FFFFFF;
    --bg-color: rgb(246, 247, 249);
    --yellow-color: #fffaed;
    --text-color: #494949;
    --text-gray-color: #595959;
    --text-gray-color-light: #C0C0C0;
    --line-graycolor: #f0f0f0;
    --backgroud-color-blur: #F6F8FA;
    --source-sans-pro-light: 300;
    --primary-police: "Raleway", sans-serif !important;
}

body {
    font-size: 20px;
    font-family: var(--primary-police);
    font-optical-sizing: auto !important;
    margin: 0;
}

p {
    color: #777777;
    line-height: 32px;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
}

.container-head {
    max-width: 1300px;
    margin: 0 auto;
}

.search-form {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;

    input {
        padding: 13px;
        border-radius: 30px;
        border: 1px solid gray;
        width: 700px;
        font-family: var(--primary-police);
        font-weight: 500;
        font-size: 15px;
    }
}

@media (max-width: 480px) {
    .desktop {
        display: none;
    }

    body {
        overflow-x: hidden;
    }

    .desktop {
        display: none;
    }

    .desktop {
        display: none;
    }

    .banner {
        padding-top: 36px;

        img {
            width: 68px;
        }

        h1 {
            font-size: 24px;
        }
    }

    .footer {
        padding: 38px 15px;
    }

    .section-content {
        .carousel-img {
            height: 200px;
        }
    }

    .section-product .nos-moutons {
        margin-top: 50px;
    }

    .product-item .blc-right .proprietaire {
        padding: 16px;
        margin: 0px 6px;
        top: 224px;
        left: 6px;
        right: 6px;
    }

    .product-item .blc-left .blc-img {
        height: 318px;
    }

    .product-item .blc-right {
        margin-top: 33px;
    }

    .section-content {
        .carousel-img {
            height: 200px;
        }
    }

    .section-product .nos-moutons {
        margin-top: 50px;
    }

    .product-item .blc-right .proprietaire {
        padding: 16px;
        margin: 0px 6px;
        top: 224px;
        left: 6px;
        right: 6px;
    }

    .product-item .blc-left .blc-img {
        height: 318px;
    }

    .product-item {
        margin-bottom: 25px;
    }

    .voir-plus {
        margin-top: 45px;
    }

    .post-page .section-1 {
        padding: 12px;
        margin-top: 50px;
    }

    .post-page .post-detail {
        width: auto;
        padding: 14px;
        margin-top: 37px;
    }

    .post-page .post-detail .post-img {
        width: auto;
        height: 274px;
        border-radius: 27px;
    }

    .post-page .post-detail .content {
        width: auto;
    }

    .contact-page .section-1 {
        margin-top: 20px;
        padding: 20px;
    }

    .auth-page .section-auth {
        margin-top: 35px;
        width: auto;
    }

    .account-page {
        padding: 20px
    }

    .landoum-detail-page .section-1 {
        margin-top: 20px;
        margin-bottom: 16px;
        padding: 14px;
    }

    .landoum-detail-page .section-1 .blc-left .title {
        font-size: 53px;
    }

    .search-form input {
        width: 290px;
    }

}

@media (max-width: 768px) {
    .desktop {
        display: none;
    }

    body {
        overflow-x: hidden;
    }

    .desktop {
        display: none;
    }

    .desktop {
        display: none;
    }

    .banner {
        padding-top: 36px;

        img {
            width: 68px;
        }

        h1 {
            font-size: 24px;
        }
    }

    .footer {
        padding: 38px 15px;
    }

    .section-content {
        .carousel-img {
            height: 200px;
        }
    }

    .section-product .nos-moutons {
        margin-top: 50px;
    }

    .product-item .blc-right .proprietaire {
        padding: 16px;
        margin: 0px 6px;
        top: 224px;
        left: 6px;
        right: 6px;
    }

    .product-item .blc-left .blc-img {
        height: 318px;
    }

    .product-item .blc-right {
        margin-top: 33px;
    }

    .section-content {
        .carousel-img {
            height: 200px;
        }
    }

    .section-product .nos-moutons {
        margin-top: 50px;
    }

    .product-item .blc-right .proprietaire {
        padding: 16px;
        margin: 0px 6px;
        top: 224px;
        left: 6px;
        right: 6px;
    }

    .product-item .blc-left .blc-img {
        height: 318px;
    }

    .product-item {
        margin-bottom: 25px;
    }

    .voir-plus {
        margin-top: 45px;
    }

    .post-page .section-1 {
        padding: 12px;
        margin-top: 50px;
    }

    .post-page .post-detail {
        width: auto;
        padding: 14px;
        margin-top: 37px;
    }

    .post-page .post-detail .post-img {
        width: auto;
        height: 274px;
        border-radius: 27px;
    }

    .post-page .post-detail .content {
        width: auto;
    }

    .contact-page .section-1 {
        margin-top: 20px;
        padding: 20px;
    }

    .auth-page .section-auth {
        margin-top: 0px;
        width: auto;
        padding: 20px;
    }

    .account-page {
        padding: 20px
    }

    .landoum-detail-page .section-1 {
        margin-top: 20px;
        margin-bottom: 16px;
        padding: 14px;
    }

    .landoum-detail-page .section-1 .blc-left .title {
        font-size: 53px;
    }

    .search-form input {
        width: 290px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .desktop {
        display: none;
    }

    body {
        overflow-x: hidden;
    }

    .desktop {
        display: none;
    }

    .desktop {
        display: none;
    }

    .banner {
        padding-top: 36px;

        img {
            width: 68px;
        }

        h1 {
            font-size: 24px;
        }
    }

    .footer {
        padding: 38px 15px;
    }

    .section-content {
        .carousel-img {
            height: 200px;
        }
    }

    .section-product .nos-moutons {
        margin-top: 50px;
    }

    .product-item .blc-right .proprietaire {
        padding: 16px;
        margin: 0px 6px;
        top: 224px;
        left: 6px;
        right: 6px;
    }

    .product-item .blc-left .blc-img {
        height: 318px;
    }

    .product-item .blc-right {
        margin-top: 33px;
    }

    .section-content {
        .carousel-img {
            height: 200px;
        }
    }

    .section-product .nos-moutons {
        margin-top: 50px;
    }

    .product-item .blc-right .proprietaire {
        padding: 16px;
        margin: 0px 6px;
        top: 224px;
        left: 6px;
        right: 6px;
    }

    .product-item .blc-left .blc-img {
        height: 318px;
    }

    .product-item {
        margin-bottom: 25px;
    }

    .voir-plus {
        margin-top: 45px;
    }

    .post-page .section-1 {
        padding: 12px;
        margin-top: 50px;
    }

    .post-page .post-detail {
        width: auto;
        padding: 14px;
        margin-top: 37px;
    }

    .post-page .post-detail .post-img {
        width: auto;
        height: 274px;
        border-radius: 27px;
    }

    .post-page .post-detail .content {
        width: auto;
    }

    .contact-page .section-1 {
        margin-top: 20px;
        padding: 20px;
    }

    .auth-page .section-auth {
        margin-top: 35px;
        width: auto;
    }

    .account-page {
        padding: 20px
    }

    .landoum-detail-page .section-1 {
        margin-top: 20px;
        margin-bottom: 16px;
        padding: 14px;
    }

    .landoum-detail-page .section-1 .blc-left .title {
        font-size: 53px;
    }

    .search-form input {
        width: 290px;
    }
}

@media (min-width: 1024px) and (max-width: 1366px) {
    .responsive-menu {
        display: none;
    }
}

@media (min-width: 1366px) {
    .responsive-menu {
        display: none;
    }
}