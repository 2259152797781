.landoum-page {

    .section-1 {
        margin-top: 37px;
    }
}

.landoum-detail-page {
    .section-1 {
        margin-top: 140px;
        margin-bottom: 20px;

        .blc-right {
            background: #8080801c;
            padding: 20px;
            font-family: var(--primary-police);

            .libelle {
                font-size: 26px;
                font-weight: 600;
                margin-bottom: 10px;
            }


            .bergerie {
                .img {
                    width: 70px;
                    height: 68px;
                    border-radius: 8px;
                    background-size: cover;
                    background-position: center;
                    margin-right: 10px;
                }

                .title {
                    font-size: 22px;
                    color: gray;
                    margin-top: 10px;
                }

                .subtitle {
                    font-size: 20px;
                }
            }

            .proprietaire {
                margin-top: 20px;

                .title {
                    font-size: 22px;
                    color: gray;
                    font-size: 22px;
                }

                .subtitle {
                    font-size: 20px;
                }
            }
        }

        .image-gallery-image {
            height: 500px;
        }

        .image-gallery-thumbnail.active,
        .image-gallery-thumbnail:focus {
            outline: none;
            border: 3px solid var(--primary-color);
        }

        .image-gallery-thumbnails-container {
            background: #8080801c;
        }

        .image-gallery-slide-wrapper {
            border: 2px solid var(--primary-color);
            padding: 20px;
            margin-bottom: 20px;
        }

        .blc-left {
            .ant-image {
                width: -webkit-fill-available;
            }

            .detail {
                margin-top: 20px;
                margin-bottom: 6px;
                font-family: var(--primary-police);

                .title {
                    font-size: 20px;
                    font-weight: 600;
                    font-family: var(--primary-police);
                    color: gray;
                }

                .subtitle {
                    margin-top: 12px;
                    font-size: 18px;
                    font-weight: 500;
                    display: flex;
                    justify-content: space-between;

                    span {
                        font-weight: normal;
                    }
                }
            }
        }
    }
}