.auth-page {
    .section-auth {
        width: 450px;
        margin: auto;
        margin-top: 120px;
    }

    a {
        color: var(--primary-color);
    }

    .cgu-link {
        font-size: 15px;
    }
}