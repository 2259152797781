    .account-header {
        background-color: var(--primary-color);
        padding: 40px 0px 38px 0;
        margin-bottom: 50px;

        .profile {
            display: flex;
            justify-content: center;
            text-align: center;
            color: white;

            h6 {
                font-weight: 700;
                font-size: 20px;
                margin: auto;
            }

            .country {
                margin-bottom: 3px;
            }

            .logout {
                color: red;
                font-size: 14px;
                cursor: pointer;
            }
        }

        .profile-img {
            width: 12%;
            margin: 20px;
        }
    }

    .account-content {
        padding: 0px 20px;
    }