.ant-input {
    background-color: white;
    box-shadow: none;
    box-sizing: border-box;
    color: rgb(0, 0, 0);
    max-width: 100%;
    outline: 0px;
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    font-family: var(--primary-police);
    line-height: 21px;
    padding: 12px 10px;
    border-radius: 7px;
}

.ant-input-password {
    background-color: white;
    box-shadow: none;
    box-sizing: border-box;
    color: rgb(0, 0, 0);
    max-width: 100%;
    outline: 0px;
    width: 100%;
    font-size: 14px;
    line-height: 21px;
    padding: 12px 10px;
    border-radius: 7px;
}

.ant-picker {
    width: 100%;
    padding: 9px;
}

.PhoneInput {
    padding: 0px 10px;
    border-radius: 7px;
    border: 1px solid #d9d9d9;
}

.PhoneInputInput {
    font-size: 14px;
    padding: 8px 10px;
    border: 1px solid #d9d9d9;
    border-top: 0px;
    border-bottom: 0px;
    border-right: 0px;
}

.PhoneInputInput:focus-visible {
    outline: 1px solid transparent;
    outline-offset: 3px;
}

.ant-radio-checked .ant-radio-inner {
    border-color: var(--primary-color);
}

.ant-radio:hover {
    border-color: var(--primary-color) !important;
    border: none !important;
}

.ant-radio-inner::after {
    background-color: var(--primary-color);
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 7px;
}

.ant-input-group-addon {
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
    color: white;
    opacity: 0.5;
}

.ant-checkbox-checked {
    .ant-checkbox-inner {
        background-color: var(--primary-color) !important;
        border-color: var(--primary-color) !important;
    }
}

.ant-form label {
    font-size: 16px;
    font-weight: 600;
}

.ant-select-selector {
    padding: 7px 10px !important;
    height: 43px !important;
    border-radius: 5px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 5px;
}

.ant-select-selector:hover {
    border-color: var(--secondary-color);
}

.ant-select:hover {
    border-color: var(--secondary-color);
}

input::placeholder,
textarea::placeholder {
    color: #777777 !important;
}

// Radio
:where(.css-dev-only-do-not-override-ni1kz0).ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: var(--primary-color);
    background-color: var(--primary-color);
}


.ant-radio-wrapper {
    span {
        font-weight: 500;
        font-family: var(--primary-police);
    }
}