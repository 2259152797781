.bergerie-page {

    .section-1 {
        margin-top: 40px;
        margin-bottom: 100px;

        .blc {
            background-color: #F5F5F5;
            margin-bottom: 100px;
            border-radius: 30px;
            height: 350px;
            position: relative;

            img {
                width: 100%;
                height: 100%;
                border-radius: 30px;
            }

            a {
                color: var(--text-color);
                text-decoration: none;
            }

            .bergerie-title {
                background: black;
                color: white;
                padding: 15px;
                border-radius: 10px;
                margin: 0px 30px;
                text-align: center;
                font-size: 22px;
                font-weight: 700;
            }

            .bergerie-content {
                position: absolute;
                bottom: -20px;
                width: -webkit-fill-available;


            }

            .bergerie-proprietaire {
                position: absolute;
                width: -webkit-fill-available;
                text-align: center;

                p {
                    margin: auto;
                    color: black;
                    font-size: 17px;
                    font-weight: 700;
                }

                span {
                    color: black;
                    font-weight: 700;
                }
            }

        }
    }
}


.bergerie-detail-page {
    .section-1 {
        margin-top: 140px;

        .blc-left {
            .title {
                font-weight: 600;
                font-size: 70px;
                line-height: 74px;
            }

            .desc {
                font-size: 18px;
                margin-top: 30px;
            }
        }

        .blc-right {
            .img {
                background-color: gray;
                height: 200px;
                margin-right: 19px;
                border-radius: 20px;
                background-size: cover;
                background-position: center;
            }
        }
    }

    .section-2 {
        margin-top: 140px;
    }
}

@media (max-width: 480px) {
    .bergerie-page .section-1 .blc {
        height: auto;
    }

    .bergerie-detail-page .section-1 {
        margin-top: 55px;
    }

    .bergerie-detail-page .section-1 .blc-left .title {
        font-weight: 600;
        font-size: 46px;
        line-height: 47px;
        text-align: center;
    }

    .bergerie-detail-page .section-2 {
        margin-top: 0px;
    }
}