.banner {
    padding-top: 85px;
    padding-bottom: 40px;
    background-color: #F9FAFC;
    border-top: 1px solid #80808042;
    position: relative;
    text-align: center;

    h1 {
        font-size: 60px;
        line-height: 74px;
        font-weight: 800;
        font-family: var(--primary-police);
        display: contents;
    }

    img {
        margin: 0px 15px;
        // width: 84px;
    }
}