.header {
    box-shadow: 0px 4px 6px #55555514;

    .nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .logo {
        img {
            width: 160px;
        }
    }

    .header-info {
        background-color: black;
        padding: 7px;
        font-size: 18px;

        .phone {
            color: white;
        }
    }

    .menu {
        ul {
            list-style: none;
            padding: inherit;
            display: inline-flex;

            li {
                a {
                    // padding: 10px;
                    // text-decoration: auto;
                    // color: var(--text-color);
                    // font-weight: 500;
                    // font-family: var(--primary-police);
                    padding: 7px 20px;
                    -webkit-text-decoration: auto;
                    text-decoration: auto;
                    color: var(--text-color);
                    font-weight: 500;
                    font-family: var(--primary-police);
                    background: var(--primary-color);
                    margin: 8px;
                    border-radius: 27px;
                    color: white;
                }

                .active {
                    // color: var(--primary-color);
                    color: white;
                    background-color: black;
                }

                a:hover {
                    // color: var(--primary-color);
                    background-color: black;
                    color: white;
                }
            }
        }
    }
}

.responsive-menu {
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 15px;
        background-color: #f5f5f5;
        border-bottom: 1px solid #ddd;
    }

    .logo img {
        width: 100px;
    }

    .menu-toggle {
        font-size: 24px;
        cursor: pointer;
        color: #333;
    }

    /* Menu */
    .content-menu {
        display: none;
        flex-direction: column;
        align-items: center;
        background-color: #f5f5f5;
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        transition: all 0.3s ease-in-out;
        z-index: 999;
    }

    .content-menu.open {
        display: flex;
    }

    .content-menu ul {
        display: grid;
        width: 100%;
        list-style: none;

        li {
            padding: 8px 0px;

            a {
                color: black;
                text-decoration: none;
                font-weight: 500;
            }

            .active {
                color: var(--primary-color);
                font-weight: 500;
            }
        }
    }
}

* {
    box-sizing: border-box;
}