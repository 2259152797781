.account-menu {
    border-right: 1px solid #00000024;

    .menu {
        font-family: 'Poppins', sans-serif;

        .menu-item {
            margin: 14px 0px;

            a,
            .logout-link {
                font-size: 18px;
                font-weight: 500;
                display: block;
                padding: 5px;
                text-decoration: none;
                color: var(--text-color);
                cursor: pointer;
            }

            .active {
                color: var(--primary-color);
                font-weight: p;
            }

        }
    }
}